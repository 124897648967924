import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Close the menu when the location changes
    setMenuOpen(false);
    setServicesMenuOpen(false);
  }, [location]);

  const closeMenu = () => {
    setMenuOpen(false);
    setServicesMenuOpen(false);
  };

  return (
    <>
      <ScrollToTop />
      <nav className="bg-blue-500 p-4 sticky top-0 z-50">
        <div className="container mx-auto flex items-center justify-between">
          <div className="md:flex md:items-center">
            <Link
              to={"/"}
              className="text-white text-lg font-semibold hidden md:block"
            >
              BestHungryGator
            </Link>
            <Link
              to={"/"}
              className="text-white text-sm font-semibold md:hidden"
            >
              BHG
            </Link>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-white hover:text-gray-300"
            >
              {menuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            className={`hidden md:flex space-x-20 ${
              menuOpen ? "hidden" : "block"
            }`}
          >
            <Link to={"/"} className="text-white hover:text-gray-300">
              HOME
            </Link>
            <Link
              to={"/gallery"}
              href="#"
              className="text-white hover:text-gray-300"
            >
              GALLERY
            </Link>
            <div
              className="relative text-white hover:text-gray-300"
              onClick={() => setServicesMenuOpen(!servicesMenuOpen)}
            >
              SERVICES ▼
              {servicesMenuOpen && (
                <div
                  className="absolute top-full left-0 bg-white border border-gray-300 mt-2 py-2 px-4 shadow-lg"
                  style={{ width: "200px" }}
                >
                  <Link
                    to={"/services/brick"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Brick Cleaning
                  </Link>
                  <Link
                    to={"/services/concrete"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Concrete Cleaning
                  </Link>
                  <Link
                    to={"/services/deck-and-fence"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Deck & Fence Soft Washing
                  </Link>
                  <Link
                    to={"/services/driveway"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Driveway Cleaning & Sealing
                  </Link>
                  <Link
                    to={"/services/gutter"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Gutter Cleaning & Maintenance
                  </Link>
                  <Link
                    to={"/services/house"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    House Washing
                  </Link>
                  <Link
                    to={"/services/pool-cage"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Pool Cage Cleaning
                  </Link>

                  <Link
                    to={"/services/roof"}
                    className="text-gray-800 block hover:text-blue-500 py-2 px-4 text-sm"
                  >
                    Roof Soft Washing
                  </Link>
                </div>
              )}
            </div>
          </div>
          <Link to={"/form"}>
            <button
              type="button"
              className="text-white bg-lime-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              GET A FAST QUOTE
            </button>
          </Link>
        </div>
        {menuOpen && (
          <div className="md:hidden">
            <Link to={"/"} className="block text-white hover:text-gray-300 p-2">
              Home
            </Link>
            <Link
              to={"/gallery"}
              className="block text-white hover:text-gray-300 p-2"
            >
              Gallery
            </Link>
            <div
              className="block text-white hover:text-gray-300 p-2"
              onClick={() => setServicesMenuOpen(!servicesMenuOpen)}
            >
              Services ▼
              {servicesMenuOpen && (
                <div className="absolute left-2 bg-white border border-gray-300 mt-1 px-4 shadow-lg text-lg">
                  {/* Add your dropdown menu options here */}
                  <Link
                    to={"/services/brick"}
                    onClick={closeMenu}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Brick Cleaning
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/concrete"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Concrete Cleaning
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/deck-and-fence"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Deck & Fence Soft Washing
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/driveway"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Driveway Cleaning & Sealing
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/gutter"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Gutter Cleaning & Maintenance
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/house"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    House Washing
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/pool-cage"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Pool Cage Cleaning
                  </Link>
                  <Link
                    onClick={closeMenu}
                    to={"/services/roof"}
                    className="text-gray-800 block hover:text-blue-500"
                  >
                    Roof Soft Washing
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </nav>
    </>
  );
}

export default Navbar;
