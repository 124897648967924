import React from "react";
import "font-awesome/css/font-awesome.min.css";

export default function TextoSobreImagen({
  imageUrl,
  logoUrl,
  title,
  subtitle,
}) {
  return (
    <div className="relative overflow-hidden">
      <div
        className="bg-cover bg-center absolute inset-0 z-0"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
      <div className="relative z-10 bg-white bg-opacity-80 p-8 md:p-16 lg:p-24 xl:p-32">
        <div className="md:flex">
          <div className="md:w-1/2 mb-8 md:mb-0 ml-[-20px] md:ml-0">
            <img
              src={logoUrl}
              alt="Logo"
              className="mx-auto max-w-xs md:max-w-sm"
            />
          </div>
          <div className="md:w-1/2">
            <h1 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-4 text-center">
              {title}
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-gray-700">
              {subtitle}
            </p>
            <div className="text-center">
              <div
                className="inline-block bg-lime-500 hover:bg-lime-200 text-white font-bold py-2 px-4 rounded-full mt-4"
                style={{ fontSize: "24px" }}
              >
                <i className="fa fa-phone mr-2"></i> (941) 444-6910
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
