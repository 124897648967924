import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Formulario from "./components/Formulario";
import Navbar from "./components/Navbar";
import ServicesDescription from "./components/services/ServicesDescription";
import NotFound from "./components/NotFound";
import ServicesMoreInfo from "./components/services/ServicesMoreInfo";

import Gallery from "./components/Gallery";
import Footer from "./components/Footer";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  {
    path: "*",
    element: (
      <>
        <Navbar />
        <NotFound />
      </>
    ),
  },
  {
    path: "/form",
    element: (
      <>
        <Navbar />
        <Formulario />,
      </>
    ),
  },
  {
    path: "/gallery",
    element: (
      <>
        <Navbar />
        <Gallery />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/brick",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../despues1.jpg"
          title="Brick Cleaning"
          description="Enhance the allure of your property with our expert brick cleaning services. At BestHungryGator, we understand the significance of clean and well-maintained brickwork. Whether you have a residential property, commercial space, or industrial facility, our dedicated team specializes in restoring the natural beauty of your bricks. <br /> Let us transform your bricks, revitalizing the aesthetics of your property. Contact us today to schedule a consultation and experience the difference our expertise can make."
        />
        <ServicesMoreInfo
          title="Why Choose Our Brick Cleaning Services?"
          description={
            "<strong>Deep Cleaning Expertise:</strong> Our skilled technicians utilize advanced pressure cleaning techniques to remove stubborn dirt, grime, moss, and stains from your bricks. We ensure a thorough cleaning process without causing any damage to the integrity of your brickwork. <br /> <strong>Preserving Aesthetics:</strong>  Over time, bricks can lose their original color and vibrancy due to environmental factors. Our brick cleaning services not only remove contaminants but also restore the natural hues of your bricks, revitalizing the overall appearance of your property. <br /> <strong>Enhanced Longevity:</strong>Regular brick cleaning not only enhances the visual appeal of your property but also contributes to the longevity of your brickwork. By removing contaminants, we help prevent potential damage, ensuring the durability of your bricks over time. <br /> <strong>Professional Team:</strong> Our team comprises experienced professionals who are trained to handle various types of bricks and surfaces. With their expertise, you can trust us to deliver exceptional results every time. <br /> <strong>Customized Solutions:</strong> Every property is unique, and so are its cleaning needs. We offer customized brick cleaning solutions tailored to your specific requirements. Whether it's a small patio or a large commercial building, we have the expertise to handle projects of all sizes. "
          }
          imageUrl="../antes1.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/concrete",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes2.jpg"
          title="Concrete Cleaning"
          description="Revitalize the appeal of your property with our specialized concrete cleaning services at BestHungryGator. We recognize the importance of pristine and well-maintained concrete surfaces, whether it's your driveway, patio, sidewalk, or commercial flooring. Our dedicated team is skilled in restoring the natural luster of your concrete, enhancing the overall aesthetics of your property. <br /> Let us transform your bricks, revitalizing the aesthetics of your property. Contact us today to schedule a consultation and experience the difference our expertise can make.6"
        />
        <ServicesMoreInfo
          title="Why Choose Our Concrete Cleaning Services?"
          description={
            "<strong>Deep Stain Removal:</strong> Our expert technicians employ advanced pressure cleaning techniques to eliminate stubborn stains, oil spills, algae, and grime deeply embedded in your concrete. We ensure a comprehensive cleaning process, leaving your surfaces spotless and vibrant. <br /> <strong>Preserving Durability:</strong> Regular concrete cleaning not only enhances the visual appeal but also extends the lifespan of your surfaces. By removing contaminants and debris, we prevent erosion and maintain the structural integrity of your concrete, saving you from costly repairs in the long run. <br /> <strong>Customized Solutions:</strong> Every concrete surface is unique, and we tailor our cleaning approach to match your specific needs. Whether you require residential concrete cleaning or commercial floor maintenance, we have the expertise to deliver exceptional results. <br /> <strong>Professional Expertise:</strong> Our team comprises skilled professionals experienced in handling a variety of concrete surfaces. Their expertise, coupled with state-of-the-art equipment, guarantees superior cleaning outcomes every time."
          }
          imageUrl="../despues2.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/deck-and-fence",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes3.jpg"
          title="Deck & Fence Soft Washing"
          description="Elevate the charm of your outdoor spaces with our specialized Deck & Fence Cleaning services at BestHungryAfter. We understand the importance of well-maintained decks and fences, which serve as the backdrop for your outdoor activities and gatherings. Our dedicated team specializes in rejuvenating these essential elements of your property, ensuring they not only look pristine but also stand the test of time."
        />
        <ServicesMoreInfo
          title="Why Choose Our Deck & Fence Cleaning Services?"
          description={
            "<strong>Thorough Restoration:</strong> Our skilled technicians employ advanced pressure cleaning techniques to remove accumulated dirt, mold, mildew, and grime from your decks and fences. We pay meticulous attention to detail, ensuring a thorough cleaning process that revitalizes the natural beauty of the wood or composite materials. <br /> <strong>Preserving Integrity:</strong> Wooden decks and fences are susceptible to weathering and damage over time. Our cleaning methods not only remove surface stains but also protect the integrity of the wood. We use environmentally friendly solutions that nourish the wood, extending its lifespan and preventing decay. <br /> <strong>Safety First:</strong> Slippery mold and algae growth on decks and fences can pose safety hazards. Our comprehensive cleaning process eliminates these hazards, creating a safe environment for your family, friends, and pets to enjoy outdoor activities without worry. <br /> <strong>Enhanced Aesthetics:</strong> Clean, well-maintained decks and fences enhance the overall curb appeal of your property. Our services not only restore the surfaces but also enhance the aesthetics, making your outdoor spaces inviting and visually appealing. <br /> "
          }
          imageUrl="../despues3.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/driveway",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes4.jpg"
          title="Driveway Cleaning & Sealing"
          description="Enhance the curb appeal of your property with our specialized Driveway Cleaning services at BestHungryGator. We recognize the significance of a clean and inviting driveway, which serves as the gateway to your home or business. Our expert technicians utilize advanced pressure cleaning techniques to remove stubborn oil stains, dirt, grime, and tire marks deeply embedded in your driveway. We ensure a comprehensive cleaning process, leaving your surfaces spotless and vibrant. Trust us to preserve the durability and aesthetics of your driveway, ensuring a lasting impression on your visitors."
        />
        <ServicesMoreInfo
          title="Why Choose Our Driveway Cleaning Services?"
          description={
            "<strong>Deep Stain Removal:</strong> Our expert technicians utilize advanced pressure cleaning techniques to eliminate stubborn oil stains, dirt, grime, and tire marks deeply embedded in your driveway. We ensure a comprehensive cleaning process, leaving your surfaces spotless and vibrant. <br /> <strong>Preserving Durability:</strong> Regular driveway cleaning not only enhances the visual appeal but also extends the lifespan of your surfaces. By removing contaminants and debris, we prevent erosion and maintain the structural integrity of your driveway, saving you from costly repairs in the long run. <br /> <strong>Enhanced Safety:</strong> Slippery algae and mold growth on driveways can pose safety hazards. Our thorough cleaning methods not only enhance the appearance but also ensure a safer environment for your family, visitors, or customers. <br /> <strong>Customized Solutions:</strong> Every driveway is unique, and we tailor our cleaning approach to match your specific needs. Whether you have a concrete, asphalt, or paver driveway, we have the expertise to deliver exceptional results. <br /> <strong>Professional Expertise:</strong> Our team comprises skilled professionals experienced in handling various types of driveways. Their expertise, coupled with state-of-the-art equipment, guarantees superior cleaning outcomes every time."
          }
          imageUrl="../despues4.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/gutter",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes5.jpg"
          title="Gutter Cleaning & Maintenance"
          description="Maintain the integrity of your property with our Expert Gutter Cleaning services at BestHungryGator. We understand the vital role gutters play in protecting your home from water damage. Our skilled technicians employ advanced cleaning techniques to remove leaves, twigs, dirt, and debris from your gutters and downspouts. We ensure a meticulous cleaning process, preventing clogs and water overflow that can lead to damage. With our comprehensive cleaning, we enhance the structural integrity of your home, prevent water damage, and create a safer environment for your family."
        />
        <ServicesMoreInfo
          title="Why Choose Our Gutter Cleaning Services?"
          description={
            "<strong>Thorough Debris Removal:</strong> Our expert technicians employ advanced cleaning techniques to remove leaves, twigs, dirt, and debris from your gutters and downspouts. We ensure a meticulous cleaning process, preventing clogs and water overflow that can lead to damage. <br /> <strong>Preventing Water Damage:</strong> Clogged gutters can cause water to overflow and damage your home's foundation, walls, and landscaping. Our professional gutter cleaning services help prevent water damage, saving you from costly repairs in the future. <br /> <strong>Enhancing Structural Integrity:</strong> By keeping your gutters clean and free from debris, we maintain the structural integrity of your home. Properly functioning gutters ensure water is directed away from your property, preventing erosion and preserving your foundation. <br /> <strong>Preventing Pest Infestations:</strong> Clogged gutters can become a breeding ground for pests such as mosquitoes, birds, and rodents. Our thorough cleaning helps prevent pest infestations, creating a healthier environment for you and your family. <br /> <strong>Customized Solutions:</strong> Every home is unique, and we tailor our gutter cleaning approach to match your specific needs. Whether you have a residential property or a commercial building, we have the expertise to deliver exceptional results. <br /> <strong>Professional Expertise:</strong> Our team consists of skilled professionals experienced in handling various types of gutters. Their expertise, coupled with specialized equipment, guarantees superior cleaning outcomes every time."
          }
          imageUrl="../despues5.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/house",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes6.jpg"
          title="House Washing"
          description="Revitalize the appearance of your home with our Professional House Washing services at BestHungryGator. We recognize the importance of a clean and well-maintained exterior, which reflects the pride you take in your residence. Our expert team uses safe and environmentally friendly cleaning agents combined with advanced pressure washing techniques to effectively clean the exterior of your house. We remove dirt, mildew, algae, and stains, preserving the paint, siding, and overall structure of your home. Trust us to enhance the curb appeal, ensure a safe environment, and create a visually appealing exterior for your property."
        />
        <ServicesMoreInfo
          title="Why Choose Our House Washing Services?"
          description={
            "<strong>Safe and Effective Cleaning:</strong> Our expert team uses safe and environmentally friendly cleaning agents combined with advanced pressure washing techniques to effectively clean the exterior of your house. We remove dirt, mildew, algae, and stains, restoring the beauty of your home without causing any damage. <br /> <strong>Preserving Paint and Siding:</strong> Regular house washing helps preserve the paint, siding, and overall structure of your home. By removing contaminants, we prevent premature wear and extend the lifespan of your exterior surfaces. <br /> <strong>Enhancing Curb Appeal:</strong> A clean and well-maintained house enhances the curb appeal of your property. Our house washing services not only refresh the appearance of your home but also make it more inviting and visually appealing. <br /> <strong>Preventing Mold and Allergies:</strong> Mold, mildew, and algae growth on your house not only affect its appearance but can also trigger allergies and respiratory issues. Our thorough cleaning eliminates these health hazards, creating a safer environment for you and your family. <br /> <strong>Customized Solutions:</strong> Every house is unique, and we tailor our house washing approach to match your specific needs. Whether you have a delicate exterior or sturdy brickwork, we have the expertise to deliver exceptional results. <br />"
          }
          imageUrl="../despues6.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/pool-cage",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes7.jpg"
          title="Pool Cage Cleaning"
          description="Elevate the aesthetics of your pool area with our Specialized Pool Cage Cleaning services at BestHungryGator. We understand the significance of a clean and inviting pool cage, providing a relaxing environment for your family and guests. Our expert technicians employ specialized cleaning techniques to remove dirt, algae, mold, and stains from your pool cage. We ensure a thorough and spotless cleaning process, enhancing the overall appeal of your pool area. Count on us to create a pristine and safe environment, making your outdoor space more enjoyable for relaxation and entertainment."
        />
        <ServicesMoreInfo
          title="Why Choose Our Pool Cage Cleaning Services?"
          description={
            "<strong>Thorough Cleaning:</strong> Our expert technicians employ specialized cleaning techniques to remove dirt, algae, mold, and stains from your pool cage, ensuring a thorough and spotless cleaning process. We pay attention to every detail, leaving your pool area looking pristine and inviting. <br /> <strong>Enhancing Pool Area Appeal:</strong> A clean pool cage enhances the overall appeal of your pool area. Our services not only remove contaminants but also refresh the appearance of your pool cage, making your outdoor space more enjoyable for relaxation and entertainment. <br /> <strong>Preventing Pest Infestations:</strong> Dirty pool cages can attract pests and insects. Our thorough cleaning helps prevent pest infestations, creating a cleaner and healthier environment for you, your family, and your guests. <br /> <strong>Ensuring Safety:</strong> Slippery algae and mold growth on pool cages can pose safety hazards. Our comprehensive cleaning process eliminates these hazards, creating a safer environment around your pool for everyone to enjoy. <br /> <strong>Customized Solutions:</strong> Every pool cage is unique, and we tailor our cleaning approach to match your specific needs. Whether you have a small residential pool cage or a large commercial one, we have the expertise to deliver exceptional results. <br />"
          }
          imageUrl="../despues7.jpg"
        />
      </>
    ),
  },
  {
    path: "/services/roof",
    element: (
      <>
        <Navbar />

        <ServicesDescription
          imageSrc="../antes8.jpg"
          title="Roof Soft Washing"
          description="Preserve the structural integrity of your roof with our Professional Roof Cleaning services at BestHungryGator. We acknowledge the impact of algae, moss, and stains on the longevity and aesthetics of your roof. Our skilled technicians use gentle yet effective cleaning methods to remove contaminants without causing damage. We prevent algae and mold growth, improve energy efficiency, and extend the lifespan of your roof. Rely on us to maintain the beauty and durability of your roof, ensuring a safe and aesthetically pleasing shelter for your property."
        />
        <ServicesMoreInfo
          title="Why Choose Our Roof Cleaning Services?"
          description={
            "<strong>Safe and Gentle Cleaning:</strong> Our expert technicians use gentle yet effective cleaning methods to remove dirt, moss, algae, and stains from your roof without causing any damage. We prioritize the safety and integrity of your roof, ensuring a thorough cleaning process. <br /> <strong>Preserving Roof Lifespan:</strong> Regular roof cleaning not only enhances the curb appeal but also extends the lifespan of your roof. By removing contaminants and preventing the growth of damaging organisms, we maintain the structural integrity of your roof, saving you from costly repairs in the future. <br /> <strong>Preventing Algae and Mold Growth:</strong> Algae and mold growth can deteriorate your roof over time. Our comprehensive cleaning process eliminates these growths, preventing damage and preserving the aesthetics of your roof. <br /> <strong>Improving Energy Efficiency:</strong> A clean roof reflects sunlight more effectively, reducing heat absorption and improving the energy efficiency of your home. Our roof cleaning services help you save on energy costs while maintaining a comfortable indoor environment. <br />"
          }
          imageUrl="../despues8.jpg"
        />
      </>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
