import React from "react";
import GoogleReviews from "./GoogleReviews";

export default function Gallery() {
  const images = [
    "gallery1.jpg",
    "gallery2.jpg",
    "gallery3.jpg",
    "antes4.jpg",
    "antes5.jpg",
    "antes6.jpg",
    // Agrega más imágenes según sea necesario
  ];

  return (
    <div>
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
          {images.map((image, index) => (
            <div
              key={index}
              className="rounded-lg overflow-hidden shadow-md ml-4 mr-4"
            >
              <img
                src={image}
                alt={`Trabajo ${index + 1}`}
                className="w-full h-auto rounded-t-lg"
              />
              {/* <div className="p-4">
        
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <GoogleReviews />
    </div>
  );
}
