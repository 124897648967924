import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Reemplaza lo siguiente con la configuración del proyecto Firebase de tu aplicación
// Ver: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyC1TBZVHsaaEh6AoqE28XvTfPlwAGm9mEc",
  authDomain: "fir-frontend-31d44.firebaseapp.com",
  projectId: "fir-frontend-31d44",
  messagingSenderId: "692671318585",
  appId: "1:692671318585:web:3027b842543a04edd81be8",
  storageBucket: "gs://fir-frontend-31d44.appspot.com",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Cloud Storage y obtén una referencia al servicio
const storage = getStorage(app);

export { storage };
