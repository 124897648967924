import React, { useState } from "react";
import emailjs from "emailjs-com";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebaseAlmacenamiento"; // Importa tu configuración de Firebase aquí
import InputGroup1 from "./InputGroup1";

function Formulario() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
    street_detail: "",
    city_zip: "",
    links: "",
  });

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);
    setFiles(filesArray);
  };

  const handleUpload = () => {
    files.forEach((file, index) => {
      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress((prevProgress) => {
            const newProgress = [...prevProgress];
            newProgress[index] = progressValue;
            return newProgress;
          });
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // Añade el enlace del archivo al formulario
            setFormData((prevData) => ({
              ...prevData,
              links: prevData.links
                ? `${prevData.links}, ${downloadURL}`
                : downloadURL,
            }));
          });
        }
      );
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_iewn3ok",
        "template_6pek2xq",
        e.target,
        "KqNwYbnd7dbhGKClC"
      )
      .then((res) => {
        console.log("Email sent successfully:", res);
        alert("Thank you! Your form has been submitted successfully.");
        window.location.href = "/"; // Redirige a la página principal
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        // Manejar errores si ocurre algún problema durante el envío del correo.
      });
  };

  return (
    <div>
      <form
        className="flex flex-col bg-white p-5 sm:p-10 gap-8 w-full sm:w-1/2 mx-auto"
        onSubmit={handleSubmit}
      >
        <InputGroup1 name="name" label="Name" id="name" autocomplete="name" />
        <InputGroup1
          name="email"
          label="Email *"
          type="email"
          id="email"
          autocomplete="email"
          required
        />
        <InputGroup1
          name="phone_number"
          label="Contact Number"
          type="text"
          id="phone_number"
          autocomplete="tel" // You can use "tel" for phone number autocomplete
        />
        <InputGroup1
          name="message"
          label="Message"
          type="textarea"
          id="message"
          autocomplete="off" // To disable autocomplete for this field
        />
        <InputGroup1
          name="street_detail"
          label="Street Detail"
          type="text"
          id="street_detail"
        />
        <InputGroup1
          name="city_zip"
          label="City / ZIP"
          type="text"
          id="city_zip"
        />
        <label style={{ display: "none" }}>
          Media:
          <input
            type="text"
            name="links"
            value={formData.links}
            onChange={handleChange}
          />
        </label>
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
          <input
            type="file"
            onChange={handleFileChange}
            multiple
            className="bg-gray-200 py-2 px-4 rounded-md"
          />
          <button
            type="button"
            onClick={handleUpload}
            className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
          >
            Upload Media
          </button>
        </div>
        {progress.map((progressValue, index) => (
          <div key={index} className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lime-600 bg-lime-200">
                  File {index + 1}
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-lime-600">
                  {progressValue}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-lime-200">
              <div
                style={{ width: `${progressValue}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-lime-500"
              />
            </div>
          </div>
        ))}

        <button
          type="submit"
          className="bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-4 border border-lime-700 rounded"
        >
          SEND
        </button>
      </form>
    </div>
  );
}

export default Formulario;
