export default function NotFound() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-semibold text-gray-800">Page not found</h1>
        <img
          alt="sad-hidro"
          src="../hidro.png"
          className="w-1/2 mx-auto" // Clase w-1/2 para el ancho y mx-auto para centrar horizontalmente
        ></img>
        <p className="mt-2 text-gray-600">
          The page you're looking for doesn't exist.
        </p>
      </div>
    </div>
  );
}
