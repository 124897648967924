import React from "react";

export default function ServicesDescription(props) {
  const { imageSrc, title, description } = props;

  return (
    <div className="bg-lime-100">
      <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="lg:order-2 mx-4">
            <img
              src={imageSrc} // Usamos la prop para la fuente de la imagen
              alt="pool"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="lg:order-1 lg:mt-0">
            <div
              className="sm:text-left text-center"
              style={{
                fontFamily: "Playfair Display, Georgia, serif",
                fontSize: "35px",
                lineHeight: "50px",
                color: "#2175ff",
              }}
            >
              {title} {/* Usamos la prop para el título */}
            </div>
            <p
              className="text-lg text-gray-700 mx-4"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
