import React from "react";

export default function ServicesMoreInfo(props) {
  const { title, description, imageUrl } = props;

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="lg:order-1 mx-4">
            <img
              src={imageUrl}
              alt="Imagen"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="lg:order-2 lg:mt-0">
            <div
              className="sm:text-left text-center"
              style={{
                fontFamily: "Playfair Display, Georgia, serif",
                fontSize: "35px",
                lineHeight: "50px",
                color: "#2175ff",
              }}
            >
              {title}
            </div>
            <p
              className="text-lg text-gray-700 mx-4"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
