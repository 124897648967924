import React from "react";

export default function GetAFastQuote() {
  const inlineStyles = {
    lineHeight: "2em",
    fontSize: "20px",
  };

  return (
    <h3
      style={{
        ...inlineStyles,
        // Agrega aquí los estilos de Tailwind CSS que desees mantener
      }}
      className="text-center text-lg text-white bg-green-400 font-semibold my-4 p-2 w-1/2 mx-auto rounded"
    >
      Get a Fast Quote
    </h3>
  );
}
