import Footer from "./components/Footer.jsx";
import Formulario from "./components/Formulario";
import GetAFastQuote from "./components/GetAFastQuote";
import Mapa from "./components/Mapa.jsx";
import Navbar from "./components/Navbar.jsx";
import QuienesSomos from "./components/QuienesSomos";
import Servicios from "./components/Servicios";
import TextoSobreImagen from "./components/TextoSobreImagen";

export default function App() {
  return (
    <>
      <Navbar />
      <TextoSobreImagen
        imageUrl="pileta.jpg"
        logoUrl="coco2.png"
        title="YOUR PREMIER EXTERIOR CLEANING SERVICES IN SOUTHWEST FLORIDA COAST"
        subtitle="We'll INSTANTLY Increase Your Home's Curb Appeal"
      />
      <QuienesSomos />
      <Servicios />
      <Mapa />
      <GetAFastQuote />
      <Formulario />
      <Footer />
    </>
  );
}

// testing push 2
