import React from "react";
import CompareImage from "react-compare-image";
import { useNavigate } from "react-router-dom";

const ServicioCard = ({
  titulo,
  imagenBefore,
  imagenAfter,
  descripcion,
  enrutado,
}) => {
  const navigate = useNavigate(); // Obtén la función de navegación

  const estiloTitulo = {
    color: "#4a5568",
    fontFamily: `"Inter var", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "22px",
    textAlign: "center",
    height: "30px",
    margin: "2px",
  };

  const handleNavigate = () => {
    navigate(enrutado); // Redirige al hacer clic en el enlace
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg">
      <div className="px-6 py-4">
        <div style={estiloTitulo}>
          <span onClick={handleNavigate} style={{ cursor: "pointer" }}>
            {titulo}
          </span>
        </div>
      </div>
      <CompareImage
        leftImage={imagenBefore}
        rightImage={imagenAfter}
        sliderLineColor="#007bff"
        sliderLineWidth={2}
      />
      <div className="px-6 pt-4 pb-2">
        <p
          style={{
            lineHeight: "26px",
            fontFamily: `"Inter var", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          }}
        >
          {descripcion}
        </p>
      </div>
    </div>
  );
};

export default function Servicios() {
  const imagenesConTextos = [
    {
      titulo: "Brick Cleaning",
      enrutado: "/services/brick",
      imagenBefore: "antes1.jpg",
      imagenAfter: "despues1.jpg",
      descripcion:
        "Our brick cleaning service restores the beauty of your brick surfaces, eliminating dirt, grime, and unsightly stains to leave your bricks looking fresh and vibrant.",
    },
    {
      titulo: "Concrete Cleaning",
      enrutado: "/services/concrete",
      imagenBefore: "antes2.jpg",
      imagenAfter: "despues2.jpg",
      descripcion:
        "Concrete cleaning rejuvenates your concrete surfaces, removing tough stains, mold, and mildew, giving your property a clean and polished appearance.",
    },
    {
      titulo: "Deck & Fence Soft Washing",
      enrutado: "/services/deck-and-fence",
      imagenBefore: "antes3.jpg",
      imagenAfter: "despues3.jpg",
      descripcion:
        "Enhance the appeal of your outdoor spaces with our deck and fence cleaning service, which removes dirt, algae, and weathering, bringing back the natural beauty of wood and other materials.",
    },
    {
      titulo: "Driveway Cleaning & Sealing",
      enrutado: "/services/driveway",
      imagenBefore: "antes4.jpg",
      imagenAfter: "despues4.jpg",
      descripcion:
        "Our driveway cleaning service eliminates oil stains, tire marks, and dirt buildup, restoring your driveway's curb appeal and safety.",
    },
    {
      titulo: "Gutter Cleaning & Maintenance",
      enrutado: "/services/gutter",
      imagenBefore: "antes5.jpg",
      imagenAfter: "despues5.jpg",
      descripcion:
        "Keep your home protected from water damage with our gutter cleaning service, ensuring that rainwater flows freely and doesn't cause structural issues.",
    },
    {
      titulo: "House Washing",
      enrutado: "/services/house",
      imagenBefore: "antes6.jpg",
      imagenAfter: "despues6.jpg",
      descripcion:
        "House washing removes dirt, grime, and mildew from your home's exterior, enhancing its overall appearance and preserving its longevity.",
    },
    {
      titulo: "Pool Cage Cleaning",
      enrutado: "/services/pool-cage",
      imagenBefore: "antes7.jpg",
      imagenAfter: "despues7.jpg",
      descripcion:
        "Our pool cage cleaning service maintains the cleanliness and visual appeal of your pool enclosure, providing a pristine environment for relaxation.",
    },
    {
      titulo: "Roof Soft Washing",
      enrutado: "/services/roof",
      imagenBefore: "antes8.jpg",
      imagenAfter: "despues8.jpg",
      descripcion:
        "Roof cleaning extends the life of your roof by removing algae, moss, and debris, preventing potential damage and improving your home's aesthetics.",
    },
  ];

  return (
    <div className="container mx-auto py-8">
      <div
        style={{
          fontFamily: "Playfair Display, Georgia, serif",
          fontSize: "44px",
          lineHeight: "61.6px",
          textAlign: "center",
          letterSpacing: "normal",
          color: "#2175ff", // Agregamos el estilo de color con el valor #2175ff
        }}
      >
        Services
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {imagenesConTextos.map((item, index) => (
          <div key={index} className="p-4">
            <ServicioCard {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}
