import React from "react";

export default function GoogleReviews() {
  const reviews = [
    { image: "review1.png", alt: "Review 1" },
    { image: "review2.png", alt: "Review 2" },
    { image: "review3.png", alt: "Review 3" },
    // Agrega más revisiones según sea necesario
  ];

  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
        {reviews.map((review, index) => (
          <div key={index}>
            <img
              src={review.image}
              alt={review.alt}
              className="w-full h-auto rounded-t-lg"
            />
          </div>
        ))}
      </div>
      <div className="text-xl mb-4 text-center">
        <a
          href="https://www.google.com/maps/place/Best+Hungry+Gator/@26.9780462,-82.1043246,17z/data=!4m8!3m7!1s0x88dca934c56421ed:0xe410e6d93c270dce!8m2!3d26.9780462!4d-82.1043246!9m1!1b1!16s%2Fg%2F11vlnnsmry?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          See all reviews
        </a>
      </div>
    </div>
  );
}
