export default function Mapa() {
  return (
    <div className="bg-lime-200">
      <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="lg:order-1 mx-4">
            <img
              src="mapa2.png"
              alt="Imagen"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="lg:order-2 lg:mt-0">
            <div
              className="sm:text-left text-center"
              style={{
                fontFamily: "Playfair Display, Georgia, serif",
                fontSize: "35px",
                lineHeight: "50px",
                color: "#2175ff", // Agregamos el estilo de color con el valor #2175ff
              }}
            >
              Where you can find us ?
            </div>
            <p className="text-lg text-gray-700 mx-4">
              {/* Are you in search of pressure cleaning experts along the Southwest
              Florida Coast? Look no further! BestHungryGator is here to make
              your property shine.  */}
              We proudly serve the entire area, including Port Charlotte, Punta
              Gorda, Englewood, North Port, Rotonda West, and Venice, offering
              top-notch pressure cleaning services. Our team consists of highly
              trained and dedicated professionals, ready to transform your home
              or business. At BestHungryGator, we take pride in being your top
              choice for pressure cleaning services on the Southwest Florida
              Coast. Let us demonstrate what we can do for you! Contact us today
              for a free quote and to schedule your next pressure cleaning. Your
              property deserves to shine, and we're here to turn that into a
              reality.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
