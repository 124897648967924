export default function QuienesSomos() {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="lg:order-2 mx-4">
            <img
              src="casaConServicios.jpg"
              alt="Imagen"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="lg:order-1 lg:mt-0">
            <div
              className="sm:text-left text-center"
              style={{
                fontFamily: "Playfair Display, Georgia, serif",
                fontSize: "35px",
                lineHeight: "50px",
                color: "#2175ff", // Agregamos el estilo de color con el valor #2175ff
              }}
            >
              Discover Excellence in Pressure Cleaning on the Southwest Florida
              Coast
            </div>
            <p className="text-lg text-gray-700 mx-4">
              <strong>Professional Expertise:</strong> With years of industry
              experience, we are the pressure cleaning experts you need. We know
              how to properly care for your property and keep it in its best
              shape.
              <br />
              <strong>Quick Response:</strong> We understand that your time is
              valuable. That's why we take pride in offering a swift response to
              your inquiries and scheduling pressure cleaning services in a
              timely manner.
              <br />
              <strong>Guaranteed Quality:</strong> We never compromise on
              quality. We use state-of-the-art equipment and advanced techniques
              to ensure you are completely satisfied with the results.
              <br />
              <strong>Personalized Attention:</strong> Every property is unique,
              and we treat each project with personalized care. We tailor our
              services to meet your specific needs.
              <br />
              <strong>Amazing Results:</strong> Our goal is to leave your
              property looking impeccable. When the job is done, you'll be
              amazed at the difference professional pressure cleaning can make.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
